import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Icon from './Icon'

const LatestPosts = ({
  title,
  date,
  time,
  slug,
  description,
  featuredImage,
  timeToRead,
  hashtags,
}) => {
  return (
    <div className="flex bg-white rounded-3xl shadow-lg overflow-hidden p-4">
      <div className="grid md:grid-cols-2 space-x-4 space-y-4 md:space-y-0">
        <div className="aspect-w-1 aspect-h-1 lg:aspect-w-21 lg:aspect-h-25">
          <picture>
            <source
              media="(max-width:600px)"
              srcSet={`https://ik.imagekit.io/gubelinlab/tr:w-311,h-311,f-auto/${featuredImage}`}
            />
            <source
              media="(min-width:640px)"
              srcSet={`https://ik.imagekit.io/gubelinlab/tr:w-336,h-400,f-auto/${featuredImage}`}
            />
            <img
              title={title}
              alt={title}
              src={`data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=`}
              data-src={`https://ik.imagekit.io/gubelinlab/tr:w-336,h-400,f-auto/${featuredImage}`}
              className="rounded-3xl shadow-lg object-cover lazyload blur-up"
            />
          </picture>
        </div>
        <div className="flex flex-col justify-between space-y-6">
          <div className="flex-1 flex flex-col justify-between">
            <div className="text-lg leading-6 font-medium space-y-4">
              <h2 className="text-xl font-semibold text-gray-900">
                <Link to={slug}>{title}</Link>
              </h2>
              <div>
                {hashtags.slice(0, 1).map(function (item, i) {
                  return (
                    <span
                      key={i}
                      className="inline-flex items-center px-2 py-0.5 rounded-xl text-sm font-medium bg-rose-100 text-rose-900"
                    >
                      {item.toUpperCase()}
                    </span>
                  )
                })}
              </div>
            </div>
            <div className="flex-1 text-lg pt-4">
              <p className="text-gray-500">{description}</p>
            </div>
          </div>
          <div className="mt-6 flex items-center">
            <div className="flex-shrink-0">
              <span className="sr-only">Gubelinlab</span>
              <Icon htmlClass={`h-10 w-10`} />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">Gubelinlab</p>
              <div className="flex space-x-1 text-sm text-gray-500">
                <time dateTime={time}>{date}</time>
                <span aria-hidden="true"> · </span>
                <span> {timeToRead} min read </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

LatestPosts.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string,
  description: PropTypes.string,
  featuredImage: PropTypes.string,
  hashtags: PropTypes.array,
  slug: PropTypes.string,
  timeToRead: PropTypes.number,
}

export default LatestPosts
