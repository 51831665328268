import React from 'react'
import { navigate } from 'gatsby'
import { globalHistory } from '@reach/router'
import Logo from './Logo'
function Sites() {
  const isHomepage = globalHistory.location.pathname === '/'
  return (
    <div className="relative bg-white overflow-hidden">
      <div
        className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
        aria-hidden="true"
      >
        <div className="relative h-full max-w-8xl mx-auto">
          <svg
            className="absolute right-full transform translate-y-1/2 translate-x-1/4 lg:translate-x-1/2"
            width="400"
            height="700"
            fill="none"
            viewBox="0 0 400 700"
          >
            <defs>
              <pattern
                id="grid-dotted"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <circle
                  cx="2"
                  cy="2"
                  r="2"
                  className="text-gray-200"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                />
              </pattern>
            </defs>
            <rect width="400" height="700" fill="url(#grid-dotted)" />
          </svg>
        </div>
      </div>
      <div className="relative max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 pb-24 mt-16 sm:mt-24">
        <div className={`${isHomepage ? 'text-center' : ''}`}>
          {isHomepage && (
            <div className="w-12 h-12 mx-auto rounded-xl mb-8 bg-gradient-to-br flex items-center justify-center from-purple-500 to-indigo-500 mb-8">
              <svg className="mx-auto" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <g clipPath="url(#ready-made-components_svg__clip0)">
                  <g filter="url(#ready-made-components_svg__filter0_dd)">
                    <path d="M2 8l14-7 14 7v16l-14 7-14-7V8z" fill="#EDE9FE" />
                  </g>
                  <path d="M2 8l14 7v16L2 24V8z" fill="#C4B5FD" />
                  <path d="M30 8l-14 7v16l14-7V8z" fill="#A78BFA" />
                </g>
                <defs>
                  <clipPath id="ready-made-components_svg__clip0">
                    <path fill="#fff" d="M0 0h32v32H0z" />
                  </clipPath>
                  <filter
                    id="ready-made-components_svg__filter0_dd"
                    x="-1"
                    y="-1"
                    width="34"
                    height="36"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix
                      in="SourceAlpha"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
                  </filter>
                </defs>
              </svg>
            </div>
          )}
          <h1 className="sm:text-lg sm:leading-snug font-semibold tracking-wide uppercase text-purple-600 mb-3">
            {isHomepage ? 'Tips Judi Online' : 'Situs Judi Online'}
          </h1>
          <p
            className="text-3xl sm:text-5xl lg:text-6xl leading-none font-extrabold text-gray-900 tracking-tight mb-8"
            dangerouslySetInnerHTML={{
              __html: isHomepage
                ? 'Tempat Terbaik <br /> Untuk Mencari Tips Judi Online'
                : 'Tempat Terbaik <br /> Untuk Bermain Judi Online',
            }}
          />

          <p
            className={`max-w-4xl text-lg sm:text-2xl font-medium sm:leading-10 space-y-6 mb-6 ${
              isHomepage ? 'mx-auto' : ''
            }`}
            dangerouslySetInnerHTML={{
              __html: isHomepage
                ? 'Gubelinlab Merupakan Pusat Tips Judi Online Terbaik Di Dunia, Semua Jenia Tips Dan Trik Bermain Judi Online Ada Disini.'
                : 'Situs Judi Online Terbaik Pilihan Gubelinlab Tempat Terbaik Untuk Anda Yang Ingin Bermain <strong>Judi Slot , Judi Bola , Judi Poker Maupun Judi Casino Online</strong> Dengan Aman Dan Terjamin',
            }}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 md:gap-8 space-y-8 md:space-y-0">
          <section className="lg:flex bg-white shadow-lg rounded-3xl lg:pr-5">
            <div className="flex flex-col justify-between flex-shrink-0 lg:shadow-lg py-5 px-4 items-center bg-gradient-to-br from-light-blue-500 to-blue-700 rounded-t-3xl lg:rounded-3xl">
              <div className="w-full text-center">
                <div className="font-semibold text-white text-base mb-2">Judi Slot Online</div>
              </div>
              <div className="my-4 relative w-full h-auto">
                <svg className="w-20 h-20 mx-auto" viewBox="0 0 500 400">
                  <path
                    d="M145.25,399.88V271.36H193v53.8L430,212.76a117.23,117.23,0,0,0,16.74-9c-1.74-1.23-3.55-2.37-5.4-3.42L193,76.34v77.84H145.23V0L464.09,159.1c57.2,31.63,41.42,73.2-14.62,96.4L145.17,400ZM274.26,256H96.8A16.11,16.11,0,0,1,80,240.8c-.67-9.18,7.64-17.47,16.81-16.8H274.18A16.11,16.11,0,0,1,291,239.19c.68,9.19-7.62,17.48-16.81,16.81Zm63.8-48H162c-22.23-.83-22.32-31.13,0-32H338.06C360.27,176.82,360.4,207.15,338.06,208Z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#ffffff"
                  />
                  <path
                    d="M48.43,240H16a16,16,0,0,1-8.93-2.67C-6,228.5.22,208,16,208H48.43a16,16,0,1,1,0,32ZM113,208H80.58a16,16,0,0,1-11.37-4.66c-10.1-10-2.83-27.46,11.37-27.34H113a16,16,0,0,1,0,32Z"
                    className="text-gray-900"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div>
                <p className="w-full text-xs text-center uppercase bg-light-blue-700 py-2 px-6 rounded-full text-white font-bold">
                  Aktifbola
                </p>
              </div>
            </div>
            <div className="flex flex-col p-5 justify-around space-y-4">
              <div className="text-lg leading-6 font-medium space-y-2">
                <h3 className="text-xl font-bold">Aktifbola</h3>
                <a
                  target={`_blank`}
                  href="https://128.199.203.213/"
                  className="group flex items-center text-purple-500 group-hover:text-purple-900"
                >
                  <svg
                    className="w-6 h-6 mr-1 text-purple-500 group-hover:text-purple-900"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                  </svg>
                  <span className="group-hover:text-purple-900">https://128.199.203.213/</span>
                </a>
              </div>
              <div className="text-base">
                <p className="text-gray-500">
                  Bagi Anda Yang Sangat Menyukai Taruhan Judi Bola, Terutama Untuk Permainan Sepak
                  Bola Maka Kami Menawarkan Situs Taruhan Judi Bola Yang Sangat Cocok Bagi Anda
                  Yaitu Aktifbola. Anda Bisa Memainkan Banyak Sekali Pasaran Judi Bola Di Situs
                  Aktifbola. Situs Taruhan Judi Bola Ini Tentu Merupakan Salah Satu Situs Judi Di
                  Indonesia Yang Telah Dipercayai Oleh Banyak Bettor Sehingga Banyak Pemain Yang
                  Daftar Di Situs Aktifbola.
                </p>
              </div>
            </div>
          </section>

          <section className="lg:flex bg-white shadow-lg rounded-3xl lg:pr-5">
            <div className="flex flex-col justify-between flex-shrink-0 lg:shadow-lg py-5 px-4 items-center bg-gradient-to-br from-purple-500 to-indigo-500 rounded-t-3xl lg:rounded-3xl">
              <div className="w-full text-center">
                <div className="font-semibold text-white text-base mb-2">Judi Poker Online</div>
              </div>
              <div className="my-4 relative w-full h-auto">
                <svg
                  className="w-16 h-16 mx-auto text-white"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M11.2,0L11.2,0C5.1,0,0,5,0,11.2l0,0c0,5.6,4.2,10.3,9.7,11.1l0,0c0.8,5.4,5.5,9.7,11.1,9.7l0,0 C27,32,32,26.9,32,20.8V0.4C32,0.2,31.8,0,31.6,0l0,0L11.2,0z M25.8,20.8c0,2.6-2,5-5,5l0,0c-2.5,0-5-1.9-5-5l0-4.2 c0-0.2-0.2-0.4-0.4-0.4l-4.2,0c-3.1,0-5-2.4-5-5l0,0c0-3,2.4-5,5-5h14.4c0.1,0,0.2,0.1,0.2,0.2l0,0V20.8L25.8,20.8z M7.4,28.3 C7.4,28.3,7.4,28.3,7.4,28.3c0-2-1.7-3.7-3.7-3.7c0,0,0,0,0,0l0,0c-2,0-3.7,1.7-3.7,3.7l0,0c0,2,1.6,3.7,3.7,3.7l0,0 C5.7,32,7.4,30.3,7.4,28.3L7.4,28.3L7.4,28.3z"
                  />
                </svg>
              </div>
              <div>
                <p className="w-full text-xs text-center uppercase bg-indigo-700 py-2 px-6 rounded-full text-white font-bold">
                  Aktifpoker
                </p>
              </div>
            </div>
            <div className="flex flex-col p-5 justify-around space-y-4">
              <div className="text-lg leading-6 font-medium space-y-2">
                <h3 className="text-xl font-bold">Aktifpoker</h3>
                <a
                  target={`_blank`}
                  href="https://128.199.167.227/"
                  className="group flex items-center text-purple-500 group-hover:text-purple-900"
                >
                  <svg
                    className="w-6 h-6 mr-1 text-purple-500 group-hover:text-purple-900"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                  </svg>
                  <span className="group-hover:text-purple-900">https://128.199.167.227/</span>
                </a>
              </div>
              <div className="text-base">
                <p className="text-gray-500">
                  Permainan Judi Online Yang Juga Sangat Disukai Para Bettor Di Indonesia Adalah
                  Permainan Poker Online. Nah, Apabila Anda Ingin Merasakan Keseruan Permainan Ini
                  Maka Cobalah Untuk Bermain Di Situs Poker Online Rekomendasi Kami Yaitu
                  Aktifpoker. Situs Yang Berprovider IDN Poker Ini Juga Menyediakan Banyak Jenis
                  Permainan Poker Dan Ditambah Dengan Bonus-Bonus Yang Sangat Besar Dan Tentunya
                  Menarik.
                </p>
              </div>
            </div>
          </section>

          <section className="lg:flex bg-white shadow-lg rounded-3xl lg:pr-5">
            <div className="flex flex-col justify-between flex-shrink-0 lg:shadow-lg py-5 px-4 items-center bg-gradient-to-br from-cool-gray-700 to-gray-900 rounded-t-3xl lg:rounded-3xl">
              <div className="w-full text-center">
                <div className="font-semibold text-white text-base mb-2">Judi Slot Online</div>
              </div>

              <div className="my-4 relative w-full h-auto flex-shrink-0">
                <svg className="w-20 h-20 mx-auto" viewBox="0 0 1000 800">
                  <defs>
                    <linearGradient
                      id="linear-gradient"
                      x1="948.74"
                      y1="52.37"
                      x2="173.14"
                      y2="530.78"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stop-color="#ffef8b" />
                      <stop offset="1" stop-color="#ffad20" />
                    </linearGradient>
                  </defs>
                  <path
                    d="M977.94,526.68a402.09,402.09,0,0,1-94.88,152.59,389.71,389.71,0,0,1-88.53,66.9,383.07,383.07,0,0,1-87.26,35.19h-.08a362.68,362.68,0,0,1-50.2,9.77c-12,1.39-24.06,2.47-36.16,2.7-6.5.31-11,.31-16.06.46l-15,.23-60.06,1.08L289.42,800c-40.94.77-74.78-32.88-75.53-75.14a77.44,77.44,0,0,1,26.6-60l1.27-1.15L441.68,492.88,167.8,445.61c-20-3.47-33.55-22.94-30.19-43.65,3-18.48,18.53-31.57,36.09-31.65l447.06-2.07A70.45,70.45,0,0,1,640.63,371c30.33,8.77,52.67,37.34,52.82,71.44A75.48,75.48,0,0,1,667.52,500l-5.08,4.31-21.81,18.64L492.26,649.71l37.43.69,60.06,1.08,15,.23c4.85.07,10.46.23,14,.15,7.32.15,14.57-.23,21.89-.77a14.63,14.63,0,0,0,1.87-.08,249.32,249.32,0,0,0,91.14-26.09c57.3-28.72,104.37-80.61,128-142.74,23.83-61.9,24.87-134.27,1.27-197.48-23.16-63.43-70.45-118.1-130.15-149.28a265.31,265.31,0,0,0-92.11-28.33c-1.35-.23-2.62-.38-4-.54-4.26-.38-8.44-.92-12.48-1l-6.2-.31-3.14-.15H581.09l-60-.08L40.86,104.4C18.3,104.4,0,85.46,0,62.21,0,39.42,17.63,20.86,39.52,20.09L520.12,3.31l60.14-2.16,30-1L614,0h4.41l8.81.08c6,0,11.73.38,17.49.77A367.8,367.8,0,0,1,779.29,36c84.79,40.34,154.27,114.25,190.8,204.1C1007.07,330,1009.84,433.75,977.94,526.68ZM621,300.36c33.06-1.77,58.47-30.83,56.75-64.91s-29.93-60.25-63-58.47L87.25,205.3c-16.31.94-29.82,14.43-30.68,31.63-.94,18.43,12.8,34.14,30.68,35.1l527.5,28.33A59,59,0,0,0,621,300.36Z"
                    fill="url(#linear-gradient)"
                  />
                </svg>
              </div>

              <div>
                <p className="w-full text-xs text-center uppercase bg-gray-700 py-2 px-6 rounded-full text-white font-bold">
                  Dewagame88
                </p>
              </div>
            </div>
            <div className="flex flex-col p-5 justify-around space-y-4">
              <div className="text-lg leading-6 font-medium space-y-2">
                <h3 className="text-xl font-bold">Dewagame88</h3>
                <a
                  target={`_blank`}
                  href="https://orcasislandtaxi.com/"
                  className="group flex items-center text-purple-500 group-hover:text-purple-900"
                >
                  <svg
                    className="w-6 h-6 mr-1 text-purple-500 group-hover:text-purple-900"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                  </svg>
                  <span className="group-hover:text-purple-900">https://orcasislandtaxi.com/</span>
                </a>
              </div>
              <div className="text-base">
                <p className="text-gray-500">
                  Nah Bagi Para Bettor Yang Suka Bertaruh Menggunakan Mesin Slot Online Maka Anda
                  Bisa Mencoba Bermain Di Situs Dewagame88. Di Situs Ini, Anda Bisa Merasakan
                  Keseruan Dan Ketegangan Dalam Bermain Slot Online, Apalagi Situs Tersebut Juga
                  Menyediakan Beragam Provider Slot Yang Dapat Dipilih Dengan Bebas. Anda Juga Hanya
                  Memerlukan 1 Akun Untuk Memainkan Seluruh Provider Judi Slot Online Di Dewagame88.
                </p>
              </div>
            </div>
          </section>

          <section className="lg:flex bg-white shadow-lg rounded-3xl lg:pr-5">
            <div className="flex flex-col justify-between flex-shrink-0 lg:shadow-lg py-5 px-4 items-center bg-gradient-to-br from-pink-500 to-rose-500 rounded-t-3xl lg:rounded-3xl">
              <div className="w-full text-center">
                <div className="font-semibold text-white text-base mb-2">Judi Poker Online</div>
              </div>
              <div className="my-4 relative w-full h-auto flex-shrink-0">
                <svg className="w-20 h-20 mx-auto" viewBox="0 0 600 561">
                  <path
                    d="M419.13,348.38A81.87,81.87,0,0,0,303.32,232.64L300,236l-3.31-3.31A81.87,81.87,0,0,0,180.87,348.38L280.53,448,258.6,469.9A151.55,151.55,0,0,1,44.21,255.64L300,0,555.8,255.64A151.55,151.55,0,0,1,341.4,469.9L319.47,448ZM300,428.53l99.66-99.6a54.35,54.35,0,0,0-76.88-76.84L300,274.86l-9.74-9.73-13-13a54.35,54.35,0,0,0-76.88,76.84Zm-3.88,42.79c-42.48,37.55-59,57.76-126.61,65.75V561h261V537.07c-67.66-8-84.13-28.2-126.61-65.75L300,467.89l-3.88,3.43"
                    fill="#fff"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div>
                <p className="w-full text-xs text-center uppercase bg-pink-700 py-2 px-6 rounded-full text-white font-bold">
                  AkunIDPro
                </p>
              </div>
            </div>
            <div className="flex flex-col p-5 justify-around space-y-4">
              <div className="text-lg leading-6 font-medium space-y-2">
                <h3 className="text-xl font-bold">Akun ID Pro</h3>
                <a
                  target={`_blank`}
                  href="https://128.199.145.239"
                  className="group flex items-center text-purple-500 group-hover:text-purple-900"
                >
                  <svg
                    className="w-6 h-6 mr-1 text-purple-500 group-hover:text-purple-900"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                  </svg>
                  <span className="group-hover:text-purple-900">https://128.199.145.239</span>
                </a>
              </div>
              <div className="text-base">
                <p className="text-gray-500">
                  Bermain Judi Online Tentu Akan Semakin Menarik Apabila Anda Mendapatkan Akun VIP
                  Dengan Keunggulan Tertentu Kan ? Oleh Karena Itu Kami Juga Ingin Merekomendasikan
                  Kepada Para Bettor 1 Situs Yang Menyediakan Pelayanan Tersebut Dan Situs Tersebut
                  Adalah Akun ID PRO VIP. Anda Bisa Membuat Akun VIP Di Situs ID PRO Khusus Untuk
                  Permainan Slot Online Atau Pun Poker Online Dengan Keunggulan Yang Dimiliki ID PRO
                </p>
              </div>
            </div>
          </section>

          <section className="lg:col-span-2 flex flex-wrap md:flex-nowrap items-center bg-gray-900 shadow-xl rounded-3xl py-6 md:py-4 px-6 md:pr-5 space-y-4 md:space-y-0 md:space-x-8">
            <h2 className="flex-none">
              <span className="sr-only">Gubelinlab</span>
              <Logo htmlClass={`w-44`} textColor={`#fff`} />
            </h2>
            <p className="flex-auto text-white text-lg font-medium">
              Kami Bayar 10 X Lipat, Jika <strong>Situs Judi Online</strong> Yang Kami
              Rekomendasikan Tidak Membayar Kemenangan Anda!
            </p>
            <button
              type="button"
              onClick={() => navigate('/tips-judi-online/')}
              className="flex items-center justify-center md:flex-none w-full md:w-auto bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-rose-500 transition-colors duration-200 text-gray-900 font-semibold rounded-xl text-lg py-3 px-4 -my-4 uppercase"
            >
              Selengkapnya
            </button>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Sites
